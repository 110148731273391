import React, { useEffect, useState } from "react";
import { blogs } from "../components/data";
import { useParams, useLocation } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ReactGA from "react-ga";

const Blog = () => {
  const { name } = useParams();
  const [blog, setBlog] = useState({});
  const [tags, setTags] = useState([]);
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    const selectedBlog = blogs.find((blog) => blog.title === name);
    setBlog(selectedBlog);
    setTags(selectedBlog?.tags?.split(",") || []);
    const allTags = [
      ...new Set(blogs.flatMap((blog) => blog.tags.split(", "))),
    ];

    console.log("allTags", allTags);
  }, [name]);

  return (
    <>
      <NavBar />
      <div className="container is-max-desktop mt-3 pb-3">
        {tags.map((tag) => {
          return <span class="tag mr-4 is-info">{tag}</span>;
        })}
        <div className="content m-2">
          <h3 className="is-align-content-center	is-align-self-center is-align-items-center">
            {blog.title}
          </h3>

          <div dangerouslySetInnerHTML={{ __html: blog.content }}></div>
          <div style={{ marginBottom: "6.8em" }}>
            <h6 className="mt-4">Author: {blog.author}</h6>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
