import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <footer
      className="footer has-text-grey-light	"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <p className="name">Yuvraj Singh Chouhan 2023</p>
      <div className="content">
        <a
          href="https://www.buymeacoffee.com/ysingh"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            alt="Buy Me A Coffee"
            // eslint-disable-next-line react/style-prop-object
            style={{ height: "50px", width: "150px" }}
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
