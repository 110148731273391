import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ContactForm from "./pages/ContactForm";

import NotFound from "./pages/NotFound";
import About from "./pages/About";
import Blog from "./pages/Blog";
import ReactGA from "react-ga";
import Tags from "./pages/Tags";
import Blogs from "./pages/Blogs";

ReactGA.initialize("UA-40114048-1");

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/blog/:name",
    element: <Blog />,
  },
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/contact",
    element: <ContactForm />,
  },
  {
    path: "/tags",
    element: <Tags />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
  },
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
