import React, { useState } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import axios from "axios";
const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("/send-email", { name, email, message });
      alert("Email sent successfully!");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };
  return (
    <>
      <NavBar />
      <div class="container is-max-desktop">
        <nav class="level">
          <section class="section">
            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Email</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="icon is-small is-right">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
              </div>
              {/* <p class="help is-danger">This email is invalid</p> */}
            </div>

            <div class="field">
              <label class="label">Message</label>
              <div class="control">
                <textarea
                  class="textarea"
                  placeholder="Message"
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>
            </div>

            {/* <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input type="checkbox" className="mr-3" />I agree to the{" "}
                  <a href="#">terms and conditions</a>
                </label>
              </div>
            </div> */}

            <div class="field is-grouped">
              <div class="control">
                <button class="button is-link" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </section>
        </nav>
      </div>
      <Footer />
    </>
  );
};

export default ContactForm;
