import React from "react";

const RecentPosts = ({ posts, title = "Latest Posts" }) => {
  return (
    <div className="pt-6 ">
      <h1 class="title mt-3">{title}</h1>
      <ul>
        {posts.map((post) => {
          return (
            <li className="mt-4	">
              <a href={`blog/${post.title}`}>{post.title}</a> -{" "}
              <span className="is-size-7	">{post.date}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default RecentPosts;
