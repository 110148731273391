import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
// import Danny from "./../danny.jpeg";
const About = () => {
  return (
    <>
      <NavBar />
      <section className="section">
        <p>
          Meet a multifaceted individual whose life is a harmonious blend of
          technology, nature, and the pursuit of inner enlightenment. As a
          passionate web developer, they navigate the digital landscape with
          finesse, crafting virtual experiences that captivate and engage users.
          With lines of code as their palette and a screen as their canvas, they
          bring ideas to life and shape the way we interact with the online
          world.
        </p>
        <p>
          Beyond the realm of technology, this individual finds solace and joy
          in the company of dogs. Their love for these faithful companions goes
          beyond mere companionship; it's a deep-rooted connection that brings
          warmth and happiness to their days. Whether it's the wagging tail of a
          loyal friend or the soft nuzzle of a furry companion, their bond with
          dogs is a testament to their compassion and empathy.
        </p>

        <p>
          Venturing into the realm of nature, this web developer's heart beats
          in tune with the rhythm of plants. The world of botany is their
          sanctuary, where they nurture and tend to green life with a sense of
          purpose. Their spare moments are transformed into opportunities for
          planting and cultivating, turning living spaces into vibrant oases of
          foliage and beauty. Through their dedication, they find tranquility in
          the growth and nurturing of plants, a connection that serves as a
          reminder of life's intricate cycles.
        </p>
        <p>
          Yet, amid the hustle and bustle of a busy life, there is a yearning
          for something deeper—a spiritual awakening. This journey is one of
          introspection, exploration, and self-discovery. In the midst of
          technological innovation and the allure of nature, they seek a
          profound connection with the essence of their being. Whether through
          meditation, self-reflection, or immersion in ancient wisdom, they are
          on a quest to unveil the layers of consciousness that lie beneath the
          surface.
        </p>
        <p>
          In the tapestry of their existence, these diverse threads—technology,
          love for dogs, affinity for plants, and the search for spiritual
          awakening—weave together a portrait of a soul in pursuit of balance.
          Their story exemplifies the beauty of embracing both the digital and
          the natural, the technical and the introspective, resulting in a life
          that is as rich and vibrant as the virtual landscapes they create and
          the gardens they tend to with care.
        </p>
        {/* <div class="tile is-ancestor">
          <div class="tile is-vertical is-8">
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <article class="tile is-child notification is-primary">
                  <p class="title">Danny</p>
                  <p class="subtitle">My Great Dane</p>
                  <figure class="image is-4by6">
                    <img src={Danny} />
                  </figure>
                </article>
                <article class="tile is-child notification is-warning">
                  <p class="title">Nanny</p>
                  <p class="subtitle">Bottom tile</p>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child notification is-info">
                  <p class="title">First Flower</p>
                  <p class="subtitle">With an image</p>
                  <figure class="image is-4by3">
                    <img src="https://bulma.io/images/placeholders/640x480.png" />
                  </figure>
                </article>
              </div>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child notification is-danger">
                <p class="title">Wide tile</p>
                <p class="subtitle">Aligned with the right tile</p>
                <div class="content"></div>
              </article>
            </div>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child notification is-success">
              <div class="content">
                <p class="title">Tall tile</p>
                <p class="subtitle">With even more content</p>
                <div class="content"></div>
              </div>
            </article>
          </div>
        </div> */}
        <div className="mt-4 box">
          <a href="/impossible-list">
            <h3 className="is-justify-content-center is-align-content-center	 is-align-items-center	is-align-self-center	">
              My Impossible List
            </h3>
          </a>
        </div>
      </section>

      <br />

      <Footer />
    </>
  );
};

export default About;
