import React from "react";
import RecentPosts from "../components/RecentPosts";
import { posts } from "../components/data";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <>
      <NavBar />
      <div class="container is-max-desktop has-background-white-bis	">
        <nav class="level">
          <section class="section">
            <h1 class="title">Yuvraj Singh Chouhan</h1>
            <p class="subtitle is-size-6 pt-4">
              Crafting Code and Cultivating Calm through Web Development and
              Meditation Mastery
            </p>

            <RecentPosts posts={posts.reverse()} />
          </section>
        </nav>
      </div>
      <Footer />
    </>
  );
};

export default Home;
