import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import NotFoundImage from "./../404.svg";
const NotFound = () => {
  return (
    <>
      <NavBar />
      <div class="container is-max-desktop">
        <div class="notification">
          <div>
            <img src={NotFoundImage} alt="404 Not Found" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
