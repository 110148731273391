import React, { useEffect, useState } from "react";
import { blogs } from "../components/data";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Tags = () => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const allTags = [
      ...new Set(blogs.flatMap((blog) => blog.tags.split(", "))),
    ];

    console.log("allTags", allTags);
    setTags(allTags);
  }, []);

  return (
    <>
      <NavBar />
      <div className="container is-max-desktop mt-3 pb-3">
        <div className="content m-2">
          {tags.map((tag) => {
            return <span class="tag is-info is-large mr-4 mt-3">{tag}</span>;
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Tags;
