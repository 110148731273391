import React from "react";
import { FaFacebook, FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
export const links = [
  {
    id: 1,
    url: "/",
    text: "home",
  },
  {
    id: 2,
    url: "/about",
    text: "about",
  },
  {
    id: 3,
    url: "/blogs",
    text: "blogs",
  },
  {
    id: 4,
    url: "/contact",
    text: "contact",
  },
  {
    id: 5,
    url: "/tags",
    text: "tags",
  },
];

export const social = [
  {
    id: 1,
    url: "https://www.facebook.com/yuvraj1",
    icon: <FaFacebook />,
  },
  {
    id: 2,
    url: "https://www.twitter.com/ysinghchouhan",
    icon: <FaTwitter />,
  },
  {
    id: 3,
    url: "https://www.linkedin.com/in/yuvraj-singh-chouhan-b1b30815/",
    icon: <FaLinkedin />,
  },
  {
    id: 4,
    url: "https://www.github.com/yuvraj88",
    icon: <FaGithub />,
  },
];

export const posts = [
  {
    id: 1,
    title: "Shambhavi Serenity: Nurturing Mind, Body, and Soul",
    date: "2023-08-15",
  },
  {
    id: 2,
    title:
      "Exploring the Web Development Journey Amidst the Sands of Jodhpur: A Tale of Learning, Friendship, and Furry Companionship",
    date: "2023-08-16",
  },
  {
    id: 3,
    title: "The Art of Self-Realization: Unveiling Shambhavi Mahamudra",
    date: "2023-08-18",
  },
  {
    id: 4,
    title:
      "Embracing Nature and Personal Growth: Unveiling Memories of My Childhood in Zawar Mines, Udaipur",
    date: "2023-08-26",
  },
  // {
  //   id: 3,
  //   title: "Exploring JavaScript ESNext Features",
  //   date: "2023-08-10",
  // },
  // {
  //   id: 4,
  //   title: "Creating Responsive Web Designs",
  //   date: "2023-08-08",
  // },
  // {
  //   id: 5,
  //   title: "Best Practices for RESTful API Design",
  //   date: "2023-08-06",
  // },
];

export const blogs = [
  {
    id: 1,
    title: "Shambhavi Serenity: Nurturing Mind, Body, and Soul",
    content: `<p>Welcome to Shambhavi Serenity, a platform where technology and spiritual growth converge. As a software developer who has embarked on the transformative journey of practicing Shambhavi Mahamudra, I invite you to join me on an extraordinary exploration of personal and professional growth.</p>
    <p>In this unique newsletter, I blend the realms of software development and the profound benefits of Shambhavi Mahamudra. Through my firsthand experiences, I share insights into how this ancient practice has shaped my mindset, enhanced my focus, and unlocked new dimensions of creativity within my coding endeavors.</p>
    <p>Shambhavi Serenity serves as a bridge between the seemingly disparate worlds of software development and spiritual practices. Discover how the art of meditation and the principles of mindfulness can empower you as a developer, fostering clarity, efficiency, and innovation in your work.</p>
    <p>Through relatable anecdotes and practical guidance, I delve into the ways in which Shambhavi Mahamudra has influenced my problem-solving abilities, decision-making skills, and overall well-being. From managing stress during tight deadlines to cultivating a work-life balance that nurtures both productivity and personal growth, I share actionable insights that can help you thrive in your software development career.</p>
    <p>Join our growing community of like-minded individuals who are passionate about the intersection of technology and self-improvement. Together, we explore the transformative potential of Shambhavi Mahamudra within the context of software development, fostering a supportive environment where we can exchange ideas, share success stories, and inspire each other's journeys.</p>
    <p>Subscribe to Shambhavi Serenity today and unlock the potential of your mind, while nurturing your professional growth as a software developer. Let's embark on this remarkable voyage of transformation together, creating a harmonious synergy between our digital pursuits and the profound wisdom of Shambhavi Mahamudra.</p>`,
    author: "Yuvraj Singh Chouhan",
    datePublished: "",
    tags: "spiritual, meditation, self-help",
    // image: <img src='1.png' />,
  },
  {
    id: 2,
    title:
      "Exploring the Web Development Journey Amidst the Sands of Jodhpur: A Tale of Learning, Friendship, and Furry Companionship",
    content: `<p>The journey of a career often mirrors the twists and turns of life itself. Each new chapter brings with it unique experiences that shape not only professional growth but personal development as well. My own journey began in the sun-soaked city of Jodhpur, located in the enchanting state of Rajasthan, India. From mastering the intricacies of web development to forming invaluable friendships and sharing moments with a beloved four-legged companion, my time in Jodhpur has been a tapestry of memorable experiences.</p><h3>The City of Sun and Sand
    </h3>
    <p>Jodhpur, often referred to as the "Suncity," is a place where tradition and modernity converge. Known for its majestic Mehrangarh Fort, vibrant markets, and warm hospitality, the city offers a unique blend of heritage and progress. It was within this dynamic backdrop that my journey as a web developer began.</p>
    <h3>Learning the Art of Web Development</h3>
    <p>As I embarked on my career path in Jodhpur, the world of web development unfolded before me. From HTML and CSS to JavaScript and backend technologies, I dived into the realm of coding, design, and user experience. The city's serene ambiance and the support of fellow developers fostered an environment conducive to learning. The challenges were met with determination, and every problem was an opportunity to refine my skills.</p>
    <h3>Unforgettable Furry Companionship </h3>
    <p>Amid lines of code and late-night debugging sessions, there was a constant and loyal presence by my side – Danny, my magnificent Great Dane. Danny was more than just a pet; he was a confidant, a source of joy, and an unwavering friend. His boundless energy and gentle demeanor were a reminder to appreciate life's simple pleasures. For seven wonderful years, Danny's presence illuminated both my personal and professional pursuits, reminding me to find balance in the midst of my busy schedule.</p>
    <h3>Cultivating Lifelong Friendships</h3>
    <p>Beyond the screen and the code, the friendships I forged in Jodhpur became pillars of support and camaraderie. The shared experiences of navigating the challenges of the tech industry, celebrating victories, and offering a helping hand during setbacks created a bond that extended beyond the confines of the workplace. These friendships enriched my journey, reminding me that growth is not just about skill acquisition but also about the connections we make along the way.</p>
    <h3>The City's Endless Inspirations</h3>
    <p>Jodhpur's architectural magnificence, rich history, and vibrant colors served as an endless well of inspiration for honing my web development skills. The city's grand structures and intricate designs deepened my understanding of user experience, while its stories of valor and tradition ignited my creativity, reminding me that every digital creation has a unique tale.</p>
    <h3>A Chapter Concluded, Memories Forever</h3>
    <p>As my time in Jodhpur drew to a close, I looked back on the years spent in the Suncity with a heart full of gratitude. The city had not only taught me about web development but also about the significance of embracing diversity, cherishing friendships, and finding solace in the companionship of a furry friend. The lessons learned and memories made in Jodhpur continue to resonate as I move forward in my career, carrying the essence of the city's warmth and wisdom with me.</p>
    <p>In retrospect, my journey in Jodhpur was not just about professional growth but about holistic transformation. It was a journey that allowed me to uncover the beauty in the convergence of technology, companionship, and the spirit of exploration. And as I venture forth into new horizons, I carry with me the indelible mark that the city of Jodhpur has left on my heart and soul.</p>`,
    author: "Yuvraj Singh Chouhan",
    datePublished: "",
    tags: "life, learning, lessons, friends, jodhpur, pets",
    // image: <img src="1.png" />,
  },
  {
    id: 3,
    title: "The Art of Self-Realization: Unveiling Shambhavi Mahamudra",
    content: `<p>In the quest for self-discovery and spiritual awakening, humanity has explored a multitude of practices and philosophies throughout history. Among these transformative techniques lies a profound and ancient meditation known as Shambhavi Mahamudra. Rooted in the rich tapestry of Indian yogic tradition, this practice holds the key to unlocking the depths of one's inner being and experiencing a profound sense of self-realization.</p>
<h3>Origins of Shambhavi Mahamudra:</h3>
<p>Shambhavi Mahamudra traces its origins back to the teachings of ancient yogis and sages of India. The word "Shambhavi" is derived from "Shambhu," an epithet for Lord Shiva, representing the auspicious and transformative aspects of consciousness. "Mahamudra" can be translated as the "great seal" or "supreme gesture," referring to the union of the individual soul with the universal consciousness.</p>
<p>The practice is part of the Kriya Yoga tradition, a powerful system of spiritual practices aimed at harmonizing the body, mind, and soul. Shambhavi Mahamudra has been handed down through a lineage of enlightened masters who realized its profound benefits and sought to share it with sincere seekers.</p>
<h3>The Essence of Shambhavi Mahamudra:</h3>
<p>At its core, Shambhavi Mahamudra is a meditation technique that combines breath control, mantra chanting, and focused awareness to awaken the dormant spiritual energy within. The practice primarily revolves around activating the Ajna Chakra, commonly known as the "Third Eye" or the "Brow Chakra." This energy center is believed to be the seat of intuition, wisdom, and higher consciousness.</p>
<h3>The process of Shambhavi Mahamudra involves the following elements:</h3>
<ul>
<li>Asana (Posture): Practitioners typically sit in a comfortable and stable meditation posture, such as Padmasana (Lotus Pose) or Siddhasana (Perfect Pose), to ensure a steady flow of energy during the practice.</li>
<li>Pranayama (Breath Control): The regulation of breath is fundamental to this meditation. Deep and rhythmic breathing techniques help calm the mind and prepare it for inner exploration.</li>
<li>Mantra Chanting: The repetition of specific mantras, often "AUM" or other sacred sounds, creates a resonance within the body and aligns the practitioner with higher vibrations.</li>
<li>Inner Focus: With eyes closed or gently gazing at the point between the eyebrows, attention is directed inwards to the Ajna Chakra. This focused awareness activates the energy center and allows for deeper exploration.</li>
<li>Surrender and Absorption: As the practice progresses, the practitioner learns to let go of thoughts and surrender to the process. This surrender leads to moments of profound stillness and insight, allowing for self-realization to unfold naturally.</li>
</ul>
<h3>Benefits of Shambhavi Mahamudra:</h3>
<p>Shambhavi Mahamudra is not merely a technique for relaxation or stress relief. Its profound effects on the mind, body, and spirit make it a transformative practice with various benefits:</p>
<ul><li>Heightened Awareness: Regular practice of Shambhavi Mahamudra cultivates heightened levels of self-awareness and introspection. This clarity of perception extends beyond the meditation cushion and positively impacts daily life.</li>
<li>Inner Peace and Calm: The meditation technique brings about a sense of inner tranquility, reducing anxiety and emotional turmoil.</li>
<li>Enhanced Intuition and Creativity: Awakening the Third Eye is said to sharpen intuition and creativity, unlocking innovative potential and problem-solving abilities.</li>
<li>Improved Concentration and Focus: Shambhavi Mahamudra enhances mental discipline, leading to improved concentration and focus in various tasks.</li>
<li>Spiritual Growth: The practice allows individuals to delve into their true nature, fostering spiritual growth and self-realization.</li>
<h3>Guidance and Precautions:</h3>
<p>Shambhavi Mahamudra is a powerful practice, and as such, it is recommended to learn it from a qualified and experienced teacher. This ensures that the practice is tailored to individual needs and capacities, minimizing the risk of any adverse effects.</p>
<p>As with any advanced meditation practice, beginners should approach Shambhavi Mahamudra with patience and consistency. It may take time to master the technique and experience its profound benefits fully.</p>
<p>In conclusion, Shambhavi Mahamudra stands as a profound tool for self-realization in the spiritual arsenal of humanity. Through its potent combination of breath, mantra, and inner focus, seekers can explore the depths of their consciousness, ultimately leading to a profound union with the universal self. Embracing the art of self-realization through Shambhavi Mahamudra can unlock the door to inner wisdom, inner peace, and enriched existence.</p>
<a href="https://ranakumbha.gumroad.com/l/breath-in-breath-out">
              Subscribe the Newsletter
            </a>
`,
    author: "Yuvraj Singh Chouhan",
    datePublished: "",
    tags: "spiritual, meditation, self-help, realization",
    // image: <img src='1.png' />,
  },
  {
    id: 4,
    title:
      "Embracing Nature and Personal Growth: Unveiling Memories of My Childhood in Zawar Mines, Udaipur",
    content: `<h6>Introduction:</h6>
    <p>Childhood memories are like treasures that shape us into who we are today. Mine were crafted in the charming embrace of Zawar Mines, Udaipur - a place rich in greenery and close to nature. As I reflect upon my early years spent in this picturesque setting, I'm reminded of the valuable lessons and experiences that have led me on a journey of self-discovery, personal growth, and lifelong friendships.</p>
    
    <h6>A Natural Haven:</h6>
    <p>Nestled within the enchanting landscapes of Zawar Mines, Udaipur, my childhood was painted with the vibrant hues of nature. Surrounded by lush greenery and the soothing melodies of chirping birds, I developed an innate appreciation for the beauty and tranquility that nature offered. These surroundings instilled a sense of wonder that would stay with me as I navigated life's twists and turns.</p>
    
    <h6>Embracing Challenges: The Relay Race that Shaped Me:</h6>
    <p>One vivid childhood memory that remains etched in my mind is the annual sports day's relay race. In that particular race, I found myself at the tail end, crossing the finish line as the last runner. Though disheartened, that seemingly small instance would serve as a turning point in my life. It was this experience that unknowingly pushed me towards introversion, making me hesitant to approach new people or ask for help.</p>
    
    <h6>The Evolution of Self:</h6>
    <p>As the years rolled by, and I found myself in the year 2020, I embarked on a journey of introspection. It was then that I realized how that seemingly minor instance from my childhood had shaped my interactions and outlook on life. The realization hit me – I had been carrying the weight of that past moment, letting it define my present and restrict my growth. With newfound clarity, I resolved to break free from this self-imposed barrier.</p>
    
    <h6>The Path to Transformation:</h6>
    <p>The journey to becoming more open and confident was not an overnight process, but a series of small, intentional steps. Gradually, I began challenging myself to initiate conversations with new people and to ask for help when needed. Each successful interaction became a stepping stone towards my personal growth. Over time, I shed my inhibitions, embracing the art of communication and forging connections.</p>
    
    <h6>Friends: The Extended Family:</h6>
    <p>Throughout this transformative period, I found unwavering support from my school friends. These friendships, nurtured amidst the innocence of childhood, blossomed into a strong foundation that still holds true today. Just as the natural surroundings of Zawar Mines became a nurturing backdrop for my growth, my friends became an integral part of my journey, providing encouragement, laughter, and shared experiences.</p>
    
    <h6>Conclusion:</h6>
    <p>My childhood in Zawar Mines, Udaipur, was a canvas painted with the hues of nature and the brushstrokes of personal experiences. From the breathtaking landscapes to the pivotal relay race, each memory holds a lesson that has contributed to my personal growth. As I look back, I am reminded that even the smallest moments can shape our paths, and the friendships formed during those times can evolve into lifelong connections. It's a testament to the power of embracing nature, facing challenges, and recognizing the potential for transformation within ourselves.</p>`,
    author: "Yuvraj Singh Chouhan",
    datePublished: "2023-08-26",
    tags: "self-help, realization, childhood",
  },
];
