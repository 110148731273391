import React from "react";
import RecentPosts from "../components/RecentPosts";
import { posts } from "../components/data";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Blogs = () => {
  return (
    <>
      <NavBar />
      <div class="container is-max-desktop has-background-white-bis	">
        <nav class="level">
          <section class="section">
            <RecentPosts posts={posts.reverse()} title="Blogs" />
          </section>
        </nav>
      </div>
      <Footer />
    </>
  );
};

export default Blogs;
